import React, { useState } from 'react';
import PreviewCompatibleImage from '../PreviewCompatibleImage';

export const TeamItem = ({ item }) => {
  const [selected, setSelected] = useState(false);
  return (
    <div className="w-full flex flex-col justify-center items-start py-16">
      <p className="font-baloo text-s4tk-blue text-3xl font-bold mb-2">
        {item.name}
      </p>
      <p className="font-baloo text-s4tk-blue text-xl mb-4">{item.title}</p>
      <div className="w-full flex flex-col md:flex-row justify-center items-start">
        <div className="w-4/5 md:w-1/2 flex flex-row justify-center items-start self-center md:self-start mb-8 md:mb-0">
          {item.image ? (
            <div className="relative group flex w-full max-w-md items-center justify-center">
              <PreviewCompatibleImage
                className="w-full max-w-md rounded-md"
                image={item.image}
                alt={item.name}
              />
              <div
                role="button"
                tabIndex={0}
                className={`select-none px-8 flex flex-col items-center justify-center transition duration-500 ease-in-out w-full h-full absolute bg-gray-200 z-10 group-hover:opacity-80 ${
                  selected ? 'opacity-80' : 'opacity-0'
                }`}
                onClick={() => setSelected(!selected)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    setSelected(!selected);
                  }
                }}
              >
                <p className="break-words mb-2 font-baloo font-bold text-s4tk-blue text-lg xs:text-2xl text-center">
                  {item.name}
                </p>
                <a
                  href={`mailto:${item.email}`}
                  className="break-all mt-2 font-baloo font-bold text-s4tk-blue text-lg xs:text-2xl text-center"
                >
                  {item.email}
                </a>
              </div>
            </div>
          ) : (
            <div className="w-full" />
          )}
        </div>
        <p className="w-full md:w-1/2 font-baloo text-black text-2xl text-left sm:text-justify md:pl-8">
          {item.description}
        </p>
      </div>
      {item.blurbs && (
        <div className="py-8 space-y-4 w-full self-center px-0 md:px-4 lg:px-8 mt-4">
          {item.blurbs.map((blurb, index) => (
            <p
              key={index}
              className="font-baloo text-black text-2xl text-left sm:text-justify"
            >
              {blurb.text}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default TeamItem;
