import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Header from '../components/Header';
import TeamContent from '../components/About/TeamContent';

export const OurTeamPageTemplate = ({ title, members }) => (
  <div className="w-full flex flex-col bg-s4tk-blue">
    <Header title={title} />
    <TeamContent members={members} />
  </div>
);

const OurTeamPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <OurTeamPageTemplate
        title={frontmatter.title}
        members={frontmatter.members}
      />
    </Layout>
  );
};

export default OurTeamPage;

export const teamPageQuery = graphql`
  query OurTeamPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "team-page" } }) {
      frontmatter {
        title
        members {
          name
          title
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          email
          description
          blurbs {
            text
          }
        }
      }
    }
  }
`;
