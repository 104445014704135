import React from 'react';
import TeamItem from './TeamItem';

export const TeamContent = ({ members }) => {
  return (
    <div className="w-full bg-white flex flex-col px-4 md:px-8 lg:px-24 xl:px-36 items-center justify-center">
      {members.map((item, index) => (
        <TeamItem key={index} item={item} />
      ))}
    </div>
  );
};

export default TeamContent;
